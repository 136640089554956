<template>
  <div class="card border-0">
    <div class="card-header f-w-600">所属区域</div>
    <div class="card-body height-500 m-0 p-0">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <!-- panel-body begin -->
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-4 col-form-label text-right"
                >标题：</label
              >
              <div class="col-lg-9 col-md-8">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="Region.name"
                  required
                />
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-4 col-form-label text-right"
                >排序：</label
              >
              <div class="col-lg-9 col-md-8">
                <input
                  class="form-control"
                  v-model="Region.code"
                  data-parsley-range="[5,10]"
                  required
                />
              </div>
            </div>
            <!-- radio-begin -->
            <div class="form-group row">
              <label
                class="col-xxl-2 col-lg-4 col-md-4 col-form-label text-right"
                >状态：</label
              >
              <div class="col-xxl-4 col-lg-8 col-lg-8">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    id="typeRadio"
                    name="type"
                    v-model="Region.value"
                    value="true"
                    checked
                  />
                  <label for="typeRadio" class="m-r-15"> 启用</label>
                  <input
                    type="radio"
                    id="typeRadio1"
                    v-model="Region.value"
                    name="type"
                    value="false"
                  />
                  <label for="typeRadio1"> 禁用</label>
                </div>
              </div>
            </div>
            <!-- radio-end -->
          </div>
        </div>
      </form>
      <div class="card-footer text-right f-w-600">
        <button
          class="btn btn-sm btn-success m-r-10"
          type="submit"
          @click="checkForm"
        >
          <i class="fa fa-save"></i> 保存
        </button>
        <button
          type="button"
          @click="$emit('close')"
          class="btn btn-sm btn-grey m-r-10"
        >
          ❌关闭
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { dictApi } from "@/api/system/dictApi";
export default {
  props: ["id"],
  data() {
    return {
      Region: {
        pid: "3a019cfc-ef88-6b72-7bc4-f7c04aa3e94c",
        name: "",
        code: "",
        count: "",
        value: "true",
        json: "",
        note: "",
      },
      pid: [],
    };
  },
  mounted() {
    if (this.id) {
      this.getDict();
    }
  },
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    getDict() {
      let that = this;
      dictApi
        .getDict({
          id: this.id,
        })
        .then((res) => that.getDictSuccess(that, res))
        .finally(() => {});
    },
    getDictSuccess(that, res) {
      that.Region = res.data;
    },
    checkForm: function (e) {
      e.preventDefault();
      const params = {
        id: this.id,
        pid: this.Region.pid,
        Name: this.Region.name,
        Code: this.Region.code,
        Value: this.Region.value,
        Json: this.Region.json,
        Note: this.Region.note,
      };

      //e.preventDefault();
      let that = this;
      if (that.id) {
        dictApi
          .UpdateDictUrl(params)
          .then((res) => that.UpdateDictUrlSuccess(that, res))
          .finally(() => {});
      } else {
        dictApi
          .CreateDict(params)
          .then((res) => that.CreateDictSuccess(that, res))
          .finally(() => {});
      }
    },
    UpdateDictUrlSuccess(that, res) {
      if (res.success) {
        // that.getDict();
        that.ToastSuccess(res);
        that.$emit("close");
      } else {
        that.ToastWarning(res);
      }
      // that.Region = res.data;
    },
    CreateDictSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        that.$emit("close");
      } else {
        that.ToastWarning(res);
      }
    },
  },
};
</script>
